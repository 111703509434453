import spicAndSpanIcon from "../../assets/icons/aggregators/spic-and-span.svg";

export const AGGREGATORS = {
  SPIC_AND_SPAN: "Spic and Span",
};

export const AGGREGATOR_OPTIONS = [
  {
    label: AGGREGATORS.SPIC_AND_SPAN,
    value: AGGREGATORS.SPIC_AND_SPAN,
    icon: spicAndSpanIcon,
    color: "#f0d218",
  },
];
